<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="logoUrl"
          alt="Muqaym"
          style="height: 50px; width: auto"
        />
        <h2 class="brand-text text-primary ml-1">
          <!-- {{ $t('muqaym') }} -->
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{
          backgroundImage: `url(' ${imgUrl}')`,
          backgroundSize: 'cover',
        }"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            مرحبا بكم على مقييم! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            يرجى تسجيل الدخول إلى حسابك لبدء المغامرة
          </b-card-text>
          <b-alert
            v-model="showAlertMessage"
            dismissible
            variant="danger"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">
                {{ errorMessage }}
              </span>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="البريد الالكتروني"
                label-for="login-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="email"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="mohamed@muqaym.sa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">كلمة المرور</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>هل نسيت كلمة السر؟</small>
                  </b-link>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  vid="password"
                  name="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  تذكرنى
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                تسجيل الدخول
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>جديد على منصة مقييم لدينا؟ </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;إنشاء حساب</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue';
import { required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useJwt from '@/auth/jwt/useJwt';
import { setUserData } from '@/auth/utils';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: false,
      password: '',
      email: '',
      sideImg: require('@/assets/images/stock/900x600/19.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      showAlertMessage: false,
      errorMessage: '',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    logoUrl() {
      return require('@/assets/images/logo/muqaym-logo.png');
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async validationForm() {
      this.showAlertMessage = false;
      this.errorMessage = '';

      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await this.$http.post('/api/login', {
              email: this.email,
              password: this.password,
              remember_me: this.status,
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم التسجيل بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            useJwt.setToken(response.data.accessToken);
            setUserData(response.data.user);
            this.$ability.update(response.data.user.abilities);
            this.$router.push('/');
          } catch (error) {
            if (!error.response) console.log(error);
            this.$refs.loginForm.setErrors(error.response.data.errors ?? []);
            this.showAlertMessage = true;
            this.errorMessage = error.response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                text: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
