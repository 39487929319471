var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"height":"50px","width":"auto"},attrs:{"fluid":"","src":_vm.logoUrl,"alt":"Muqaym"}}),_c('h2',{staticClass:"brand-text text-primary ml-1"})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",style:({
        backgroundImage: ("url(' " + _vm.imgUrl + "')"),
        backgroundSize: 'cover',
      }),attrs:{"lg":"4"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"})]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"8"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" مرحبا بكم على مقييم! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" يرجى تسجيل الدخول إلى حسابك لبدء المغامرة ")]),_c('b-alert',{attrs:{"dismissible":"","variant":"danger"},model:{value:(_vm.showAlertMessage),callback:function ($$v) {_vm.showAlertMessage=$$v},expression:"showAlertMessage"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"AlertCircleIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)]),_c('validation-observer',{ref:"loginForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"البريد الالكتروني","label-for":"login-email"}},[_c('validation-provider',{attrs:{"vid":"email","name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"mohamed@muqaym.sa"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("كلمة المرور")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password-v2' }}},[_c('small',[_vm._v("هل نسيت كلمة السر؟")])])],1),_c('validation-provider',{attrs:{"vid":"password","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" تذكرنى ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" تسجيل الدخول ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("جديد على منصة مقييم لدينا؟ ")]),_c('b-link',{attrs:{"to":{ name: 'register' }}},[_c('span',[_vm._v(" إنشاء حساب")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }